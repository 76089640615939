import { Component, OnInit } from '@angular/core';
import { StoreService } from '@services';

@Component({
  selector: 'cm-desktop-message-list-switch',
  templateUrl: './desktop-message-list-switch.component.html',
  styleUrls: ['./desktop-message-list-switch.component.scss'],
})
export class DesktopMessageListSwitchComponent implements OnInit {
  isDesktop = false;

  constructor(private storeService: StoreService) {}

  ngOnInit() {
    this.isDesktop = this.storeService.store.activeBreakpoints.isDesktop;
  }
}
