<div class="d-flex flex-column cm-menu p-3">
  <div>
    <div class="row justify-content-between">
      <img class="rounded-circle col-auto"
        width="48"
        height="48"
        src="https://picsum.photos/48/48" />
      <button mat-flat-button
        (click)="closeSidenav()"
        class="col-auto"
        tabindex="-1">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>

  <div class="m-0 mt-1 mat-subheading-2">{{ user?.firstName }} {{ user?.lastName }}</div>
  <!-- <div class="m-0 mb-2 border-bottom mat-subheading-1">{{ user?.role }}</div> -->

  <div class="cm-scroll-y flex-grow-1">
    <div *ngIf="storeService.store?.children?.length">
      <button mat-flat-button
        *ngFor="let menuPosition of menuItems"
        class="w-100 text-left pl-0 mt-2"
        [routerLink]="menuPosition.url"
        routerLinkActive="active-link"
        (click)="closeSidenav()">
        <mat-icon matPrefix>{{ menuPosition.icon }}
        </mat-icon>
        <span class="pl-2">{{ menuPosition.label | translate }}</span>
      </button>

      <button mat-flat-button
        (click)="onRefreshApp()"
        class="w-100 text-left pl-0 mt-2">
        <mat-icon matPrefix>refresh</mat-icon>
        <span class="pl-2">{{ 'COMMON.BUTTON.REFRESH_APP' | translate }}</span>
      </button>
    </div>

  </div>

  <div class="cm-menu-footer">

    <button mat-flat-button
      (click)="signOut()"
      class="text-center w-100"
      color="accent">
      {{ 'COMMON.BUTTON.LOGOUT' | translate }}
    </button>

    <div class="text-center py-2">
      <a mat-button
        href="https://www.childrenmonitor.it/parent-app-privacy-policy.html"
        target="_blank">{{'COMMON.APP.PRIVACY_POLICY' | translate}}</a>

    </div>

    <div class="mat-caption text-center">{{ 'COMMON.APP.VERSION' | translate }}: {{ appVersion }}</div>
  </div>
</div>