<div class="cm-fixed-viewport p-0">
  <div class="children-list row align-items-center p-0 m-0 flex-nowrap"
    *ngIf="storeService.store.children && storeService.store.children.length > 1">

    <div class="child col-auto"
      *ngFor="let child of storeService.store.children"
      [ngClass]="{ 'child-active': storeService.store.child && storeService.store.child.id === child.id }"
      (click)="onSelectChild(child)">
      <div class="text-center child-avatar">
        <img src="/assets/img/placeholder-child.png" />
      </div>

      <div class="row text-center">
        <div class="col-auto child-title">{{ child.firstName }}</div>
        <div class="col">
          <mat-icon [ngClass]="child.positionIcon.color">{{ child.positionIcon.icon }}</mat-icon>
        </div>
      </div>
    </div>
  </div>

  <cm-tracking-map *ngIf="storeService.store.child"
    [route]="storeService.store?.child?.childrenList?.routePlan?.currentRoute"
    [busId]="storeService.store?.child?.childrenList?.routePlan?.currentBus?.id"></cm-tracking-map>
</div>