<div class="cm-fixed-viewport d-flex flex-column justify-content-center align-items-center">
  <img src="assets/img/error.png"
    class="w-100" />

  <h1 class="my-4">{{ 'COMMON.NOT_FOUND_PAGE.ERROR' | translate }}</h1>

  <p>{{ 'COMMON.NOT_FOUND_PAGE.MESSAGE' | translate }}</p>

  <a mat-flat-button
    color="accent"
    class="w-100"
    [routerLink]="['/']">
    {{ 'COMMON.NOT_FOUND_PAGE.BACK_BUTTON' | translate }}
  </a>
</div>