import { Component, Injector } from '@angular/core';
import { CommonLayout } from '@common/core/components/layout/common-layout.component';

import { StoreService } from '@services';
@Component({
  selector: 'cm-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
})
export class MobileLayoutComponent extends CommonLayout {
  constructor(public injector: Injector, public storeService: StoreService) {
    super(injector);
  }
  updateLayout() {
    const data = this.route.snapshot.firstChild.data;
    this.showHeader = data.showHeader;
    this.showFooter = data.showFooter;
  }
}
