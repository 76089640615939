<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <ng-container #sidenavContent></ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="container-fluid cm-desktop">
      <div class="row">
        <div class="col-7 cm-content"
          [class.cm-content-without-header]="!showHeader">
          <cm-header *ngIf="showHeader"></cm-header>
          <router-outlet></router-outlet>
        </div>
        <div class="col cm-content-without-header p-0">
          <ng-template [cdkPortalOutlet]="secondaryComponentPortal"></ng-template>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>