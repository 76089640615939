import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { BitfAuth0AuthService } from '@bitf/services/auth/auth0/bitf-auth0-auth.service';

import { CONSTANTS } from '@constants';
import { BITF_CONFIGS } from '@configs';
import { User } from '@models';
import { UsersService } from '@services';
import { IBitfApiResponse } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfAuth0AuthService {
  usersService: UsersService;
  breakpointObserver: BreakpointObserver;
  router: Router;

  constructor(protected injector: Injector) {
    super(injector);
    this.usersService = this.injector.get(UsersService);
    this.router = this.injector.get(Router);

    this.initAuth0();
  }

  loadUser() {
    this.usersService.getMe().subscribe(
      (response: IBitfApiResponse<User>) => {
        this.setUser(response.content);
        this.onLoginSuccess();
        this.handleRedirect();
      },
      errror => {
        this.onLoginError(errror.message);
      }
    );
  }

  handleRedirect() {
    this.router.navigate([BITF_CONFIGS.urls.homePageUrl]);
  }

  initAuth0() {
    let redirectUri: string;
    let returnTo: string;
    this.breakpointObserver = this.injector.get(BreakpointObserver);
    const isMobile = this.breakpointObserver.isMatched([Breakpoints.XSmall, Breakpoints.Small]);
    if (isMobile) {
      redirectUri = `${location.origin}/m/sign-in`;
      returnTo = `${location.origin}/m/sign-in`;
    } else {
      redirectUri = `${location.origin}/sign-in`;
      returnTo = `${location.origin}/sign-in`;
    }

    Object.assign(CONSTANTS.auth0Config, {
      redirectUri,
      returnTo,
    });
    super.initAuth0();
  }
}
