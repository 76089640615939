import { NgModule } from '@angular/core';

import { SharedModule } from '@web/shared/shared.module';

import { MapRoutingModule } from './map-routing.module';
import { ParentsMapComponent } from './parents-map/parents-map.component';
import { TrackingMapComponent } from './tracking-map/tracking-map.component';

const NG_MODULES = [MapRoutingModule, SharedModule];
const NG_COMPONENTS = [TrackingMapComponent];
const NG_SHARED_COMPONENTS = [ParentsMapComponent];
@NgModule({
  imports: [...NG_MODULES],
  declarations: [...NG_COMPONENTS, ...NG_SHARED_COMPONENTS],
  exports: [...NG_SHARED_COMPONENTS],
})
export class MapModule {}
