import { Injectable, Injector } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { interval, Subject, merge, of, Observable } from 'rxjs';
import { tap, switchMap, startWith, catchError } from 'rxjs/operators';

import { updateAppLayout, initDynamicLayoutUrl } from '@bitf/utils/bitf-responsive.utils';
import { BitfAppSessionService } from '@bitf/services/app-session/bitf-app-session.service';
import { BitfPwaService } from '@bitf/services/pwa/bitf-pwa.service';
import { BitfLogDnaSenderService } from '@bitf/services/logger/bitf-log-dna-sender.service';
import { BitfTryCatch } from '@bitf/core/decorators/bitf-try-catch.decorator';

import { IBitfApiResponse, IBitfApiRequest } from '@interfaces';
import { CONSTANTS } from '@constants';
import { ResponsiveService, UsersService } from '@services';
import { Store, Child } from '@models';
import { EStoreActions, EBitfApiSortDirection, EBitfInterceptors } from '@enums';

@Injectable({
  providedIn: 'root',
})
export class AppSessionService extends BitfAppSessionService {
  pollingInterval = 6000;
  pollForAppData$: Subject<any>;
  triggerPolling$ = new Subject();
  polling$: Observable<any>;
  isFirstPolling = true;

  texts = {
    header: CONSTANTS.DEFAULT_APP_HEADER,
  };

  set headerText(headerText: string) {
    // NOTE: this is to prevent ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.texts.header = this.storeService.store.activeBreakpoints.isDesktop
        ? CONSTANTS.DEFAULT_APP_HEADER
        : headerText;
    });
    this.bitfMetadataService.updateTitle(headerText);
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    public responsiveService: ResponsiveService,
    public injector: Injector,
    private usersService: UsersService,
    private bitfLogDnaSenderService: BitfLogDnaSenderService,
    private bitfPwaService: BitfPwaService
  ) {
    super(injector);
  }

  init() {
    super.init();
    this.initSession();
    this.initPolling();

    initDynamicLayoutUrl(this.router, this.storeService);
    this.initBreakpointListener();
    this.responsiveService.init();

    // PWA
    this.bitfPwaService.init();
    this.bitfPwaService.initManifest();
    this.bitfPwaService.initSwUpdate();
    this.bitfPwaService.initBeforeInstallPrompt();
    this.bitfPwaService.initOnlineChecker();
    this.handleSwUpdate();
  }

  initLogSender() {
    this.bitfLogDnaSenderService.init();
  }

  initPolling() {
    // of(1).pipe(
    this.polling$ = merge(this.triggerPolling$, interval(this.pollingInterval)).pipe(
      startWith(1),
      switchMap(() =>
        this.usersService
          .getChildren({
            disableHideLoader: true,
            embed: [
              'childrenLists($expand=routePlans($expand=buses,routes($expand=locations),staff))',
              'class($expand=school)',
            ],
            sorting: [{ property: 'fullName', direction: EBitfApiSortDirection.ASC }],
            headers: [{ headerName: EBitfInterceptors.BITF_RETRY_INTERCEPTOR, value: 'disable' }],
          } as IBitfApiRequest)
          .pipe(
            catchError(e => {
              return of(false);
            })
          )
      ),
      tap((response: IBitfApiResponse<Child[]>) => {
        if (!response) {
          return;
        }
        this.storeService.setStore(() => {
          if (!this.storeService.store.children.length) {
            this.storeService.setStore(store => {
              store.children = response.content;
            }, EStoreActions.ADD_CHILDREN);
            return;
          }

          this.storeService.setStore(() => {
            this.updateChildrenProps(response.content);
            this.addNewChildren(response.content);
            this.removeDeletedChildren(response.content);
          }, EStoreActions.UPDATE_CHILDREN);
        }, EStoreActions.POLLING);
      }),
      catchError(e => {
        console.log(e);
        return of(1);
      })
    );
  }

  @BitfTryCatch()
  private updateChildrenProps(children: Child[]) {
    this.storeService.store.children.forEach((c: Child) => {
      const newChildData = children.find((c2: Child) => c2.id === c.id);
      if (newChildData) {
        Object.assign(c, newChildData);
      }
    });
  }

  @BitfTryCatch()
  private addNewChildren(children: Child[]) {
    children.forEach((c: Child) => {
      if (!this.storeService.store.children.find((sC: Child) => c.id === sC.id)) {
        this.storeService.store.children.push(c);
      }
    });
  }

  @BitfTryCatch()
  private removeDeletedChildren(children: Child[]) {
    this.storeService.store.children = this.storeService.store.children.filter(
      (c: Child) => !!children.find((c2: Child) => c2.id === c.id)
    );
  }

  private initBreakpointListener() {
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe(result => {
        if (result.matches) {
          this.storeService.setStore((store: Store) => {
            store.activeBreakpoints.isDesktop = true;
            store.activeBreakpoints.isMobile = false;
          }, EStoreActions.BREACKPOINT);
        } else {
          this.storeService.setStore((store: Store) => {
            store.activeBreakpoints.isDesktop = false;
            store.activeBreakpoints.isMobile = true;
          }, EStoreActions.BREACKPOINT);
        }
        updateAppLayout(this.router, result.matches);
      });
  }

  private handleSwUpdate() {
    this.bitfPwaService.swUpdate$.subscribe(() => {
      this.bitfPwaService.showDefaultSwUpdateDialog();
    });
  }

  initSession() {}

  initSettings() {}

  cleanSession() {}
}
