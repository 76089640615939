import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';

import { SignInComponent } from './sign-in/sign-in.component';
import { LoginLoaderComponent } from './login-loader/login-loader.component';
import { PrivacyConditionsComponent } from './privacy-conditions/privacy-conditions.component';

const NG_COMPONENTS = [SignInComponent, LoginLoaderComponent, PrivacyConditionsComponent];

@NgModule({
  imports: [SharedModule, RouterModule],
  declarations: [...NG_COMPONENTS],
})
export class LoginModule {}
