import { Component } from '@angular/core';

import { MenuComponent } from '../menu/menu.component';
import { CommonHeaderComponent } from '@common/core/components/header/common-header.component';

@Component({
  selector: 'cm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends CommonHeaderComponent {
  menuComponent = MenuComponent;
}
