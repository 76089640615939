import { CONSTANTS as COMMON_CONSTANTS } from '@common/constants/common.constants';

import { EUserRoles } from '@enums';
import { environment } from '@env/environment';

// tslint:disable-next-line:max-line-length
import { FullScreenMessageComponent } from '@web/shared/components/full-screen-message/full-screen-message.component';
// tslint:disable-next-line:max-line-length
import { AbsentChildDialogComponent } from '@web/modules/children/children-status-list/absent-child-dialog/absent-child-dialog.component';
// tslint:disable-next-line:max-line-length
import { BitfMatOkCancelDialogComponent } from '@bitf/core/components/ui/ok-cancel-dialog/material';

const auth0ClientId = {
  development: '029JI16wcmkLQFEsNeoEqAEU7Tmrc0dx',
  'remote-development': '029JI16wcmkLQFEsNeoEqAEU7Tmrc0dx',
  staging: 'pv356lm8o7libklllc5Y8TfWKq5cYovB',
  production: '9p7Dzwc5de3Xc0kg2wgV0DjXWNycI7Xo',
};

const auth0Domain = {
  development: 'children-monitor-dev.eu.auth0.com',
  'remote-development': 'children-monitor-dev.eu.auth0.com',
  staging: 'children-monitor-test.eu.auth0.com',
  production: 'children-monitor.eu.auth0.com',
};

export const WEB_CONSTANTS = {
  appName: 'Children monitor - parent app',
  fullScreenMessageComponent: FullScreenMessageComponent,
  absentChildDialogComponent: AbsentChildDialogComponent,
  okCancelDialogComponent: BitfMatOkCancelDialogComponent,
  // NOTE: redirectUri and returnTo are set in the auth.service initAuth0 function based on device (/m)
  auth0Config: {
    clientID: auth0ClientId[environment.name] || auth0ClientId.development,
    domain: auth0Domain[environment.name] || auth0Domain.development,
    responseType: 'token id_token',
    scope: 'openid',
    audience: environment.audience,
    redirectUri: '',
    returnTo: '',
  },
  allowedUserTypes: [EUserRoles.PARENT],
  userRole: EUserRoles.PARENT,
  LOCAL_STORAGE_VERSION: 1,
};

export const CONSTANTS = Object.assign(COMMON_CONSTANTS, WEB_CONSTANTS);
