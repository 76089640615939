import { Component, OnInit, ViewChild, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

import { Subscription } from 'rxjs';

import { Route, Bus } from '@models';
import { BusesService, StoreService } from '@services';
import { CONSTANTS } from '@constants';
import { GoogleMapComponent } from '@common/shared/google-map/google-map.component';
import { IBitfApiResponse } from '@interfaces';
import { Child } from '@models';

@Component({
  selector: 'cm-tracking-map',
  templateUrl: './tracking-map.component.html',
  styleUrls: ['./tracking-map.component.scss'],
})
export class TrackingMapComponent extends GoogleMapComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  route: Route;
  @Input()
  busId: number;

  private subscription: Subscription;
  showPlaceholder = false;
  isMapCentered = false;

  constructor(private busesService: BusesService, private storeService: StoreService) {
    super();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.route || !changes.route.currentValue) {
      this.showPlaceholder = true;
    }

    if (
      changes.route &&
      changes.route.currentValue &&
      (!changes.route.previousValue || changes.route.currentValue.id !== changes.route.previousValue.id)
    ) {
      if (!this.isMapInitalised) {
        this.mapOptions = {
          ...CONSTANTS.MAP_CONF.DEFAULT_OPTIONS,
          center: this.route ? this.route.centerLatLng : CONSTANTS.MAP_CONF.DEFAULT_OPTIONS.center,
        };
        this.initMap();
        this.initDirectionsRenderer();
      }
      this.showDirections(changes.route.currentValue);
      this.showPlaceholder = false;
    }

    if (
      changes.busId &&
      changes.busId.currentValue &&
      changes.busId.currentValue !== changes.busId.previousValue
    ) {
      this.createMapMarker(0, 0, { icon: 'assets/icons/bus_icon.svg' });
      this.startTracking(changes.busId.currentValue);
      this.showPlaceholder = false;
    }
  }

  startTracking(busId: string) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.busesService.busPolling({ id: busId }).subscribe(
      (response: IBitfApiResponse<Bus>) => {
        this.mapMarker.setPosition(response.content.location.googleLatLng);
        if (!this.isMapCentered) {
          this.map.setCenter(response.content.location.googleLatLng);
          this.isMapCentered = true;
        }
      },
      () => {}
    );
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }
}
