<mat-toolbar>
  <mat-toolbar-row class="pl-1 d-flex">
    <button mat-icon-button
      (click)="openMenu()"
      class="mr-3"
      tabindex="-1">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="mr-2">{{ appSessionService.texts.header | translate }}</span>
    <mat-chip-list class="ml-auto">
      <mat-chip *ngIf="bitfPwaService.onlineStatus === eBitfOnlineStates.OFFLINE"
        color="warn"
        matRipple
        [matRippleDisabled]="true"
        selected>Offline</mat-chip>
    </mat-chip-list>
  </mat-toolbar-row>
</mat-toolbar>