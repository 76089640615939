import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { Child } from '@models';
import { AppSessionService, StoreService } from '@services';
import { EChildPosition, EStoreActions } from '@enums';

@Component({
  selector: 'cm-parents-map',
  templateUrl: './parents-map.component.html',
  styleUrls: ['./parents-map.component.scss'],
})
export class ParentsMapComponent implements OnInit {
  children: Child[];
  eChildPosition = EChildPosition;
  pollingSubscription: Subscription = new Subscription();

  constructor(public storeService: StoreService, private appSessionService: AppSessionService) {}

  ngOnInit() {
    if (this.storeService.store.activeBreakpoints.isMobile) {
      this.appSessionService.headerText = bitfToTranslate('MAP.PARENTS_MAP.HEADER');
    }
  }

  onSelectChild(child: Child) {
    this.storeService.setStore(store => {
      store.child = child;
    }, EStoreActions.SELECT_CHILD);
  }
}
