import { BitfStore } from '@bitf/core/models/bitf-store.model';

import { Child, User } from '@models';

export class Store extends BitfStore {
  user: User;
  children: Child[] = [];
  _child: Child;

  constructor() {
    super();
  }

  set child(child) {
    this._child = child;
  }

  get child() {
    if (!this._child && this.children && this.children[0]) {
      this._child = this.children[0];
    }
    return this._child;
  }
}
