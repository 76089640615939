<mat-toolbar>
  <mat-toolbar-row class="d-flex justify-content-between cm-footer-links p-0">

    <a mat-flat-button
      routerLink="map"
      routerLinkActive="active-link">
      <mat-icon>map</mat-icon>
    </a>

    <a mat-flat-button
      routerLink="children/list"
      routerLinkActive="active-link">
      <mat-icon>people</mat-icon>
    </a>

    <a mat-flat-button
      routerLink="messages"
      routerLinkActive="active-link">
      <mat-icon>message</mat-icon>
    </a>

  </mat-toolbar-row>
</mat-toolbar>