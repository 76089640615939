import { Injectable } from '@angular/core';

import { BitfStoreService } from '@bitf/services/store/bitf-store.service';

import { Store } from '@models';

@Injectable({
  providedIn: 'root',
})
export class StoreService extends BitfStoreService<Store> {
  constructor() {
    super({ StoreClass: Store });
  }
}
