<div class="cm-fixed-viewport d-flex flex-column justify-content-center align-items-center"
  *ngIf="isVisible">
  <div class="background-contain-img logo-img"></div>
  <h3 class=" text-center"> {{ 'CORE.WELCOME_PAGE.DESCRIPTION' | translate }}</h3>

  <div class="background-contain-img child-img"></div>

  <div *ngIf="authState === eBitfAuthState.TOKEN_RETRIEVED">
    <h3>{{ 'LOGIN.IN_PROGRESS_PLEASE_WAIT' | translate }}</h3>
    <mat-progress-bar mode="indeterminate"
      color="accent"></mat-progress-bar>
  </div>

  <div *ngIf="authState === eBitfAuthState.LOGIN_ERROR"
    class="pt-3 text-center">
    <h2 class="m-0">{{ 'LOGIN.ERROR' | translate }}</h2>
  </div>

  <div *ngIf="authState === eBitfAuthState.EMAIL_NOT_VERIFIED"
    class="pt-3 text-center">
    <h2 class="m-0">{{ 'BITF.AUTH.EMAIL_NOT_VERIFIED' | translate }}</h2>
  </div>

  <div *ngIf="authState === eBitfAuthState.TO_BE_LOGGED || 
    authState === eBitfAuthState.LOGIN_ERROR || 
    authState === eBitfAuthState.EMAIL_NOT_VERIFIED">
    <button mat-flat-button
      color="accent"
      class="w-100 my-3 text-uppercase"
      (click)="onSignIn()">
      {{ 'LOGIN.BUTTON.SIGN_IN' | translate }}
    </button>

    <button
      *ngIf="authState === eBitfAuthState.LOGIN_ERROR || authState === eBitfAuthState.EMAIL_NOT_VERIFIED"
      mat-flat-button
      color="accent"
      class="w-100 mb-3 text-uppercase"
      (click)="onChangeAccount()">
      {{ 'LOGIN.BUTTON.CHANGE_ACCOUNT' | translate }}
    </button>

    <div class="row justify-content-center">
      <a class="col-auto"
        href="https://www.childrenmonitor.it/parent-app-privacy-policy.html"
        target="_blank">{{'COMMON.APP.PRIVACY_POLICY' | translate}}</a>
    </div>

    <div class="row justify-content-center mt-3">
      <div class="col-24 text-center">
        <span color="medium">V{{APP_VERSION}}</span>
        <span *ngIf="!environment.production"> - {{environment.name}}</span>
      </div>
    </div>
  </div>
</div>