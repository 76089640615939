import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { switchMap, filter, tap } from 'rxjs/operators';

import { AppSessionService } from '@services';
import { environment } from '@env/environment';
import { StoreService } from '@services';
import { EStoreActions } from '@enums';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cm-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  polling$: Observable<any>;

  constructor(
    private appSessionService: AppSessionService,
    private storeService: StoreService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router
  ) {
    console.log('ENV', environment);

    this.appSessionService.init();
    this.initMatIconRegistry();
  }

  ngOnInit(): void {
    this.polling$ = this.appSessionService.polling$.pipe(
      tap(() => {
        if (!this.storeService.store.children.length) {
          // Redirect to force the user to add the first child
          this.router.navigate(['/children/create']);
        }
      })
    );
    this.initApp();
  }

  initApp() {
    let pollingSubscription: Subscription;

    this.storeService
      .selectStore(EStoreActions.SET_USER)
      .pipe(
        tap(data => {
          if (data.store.user && !!data.store.user.id) {
            pollingSubscription = this.polling$.subscribe();
          } else {
            if (pollingSubscription) {
              pollingSubscription.unsubscribe();
            }
          }
        })
      )
      .subscribe();
  }

  private initMatIconRegistry() {
    this.matIconRegistry.addSvgIcon(
      'battery_20',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_20.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_30',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_30.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_50',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_50.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_60',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_60.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_80',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_80.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_90',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_90.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_full',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_full.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_alert',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_alert.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'battery_unknown',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/material-icons/battery_unknown.svg')
    );
  }
}
