import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '@services';
import { EBitfAuthState } from '@enums';
import { BITF_CONFIGS } from '@configs';
import { CONSTANTS } from '@constants';
import { APP_VERSION } from '@env/version';
import { environment } from '@env/environment';

@Component({
  selector: 'cm-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
  authState: EBitfAuthState = EBitfAuthState.TO_BE_LOGGED;
  eBitfAuthState = EBitfAuthState;
  subscriptions: Subscription = new Subscription();
  isVisible = false;
  popup;
  APP_VERSION = APP_VERSION;
  environment = environment;

  constructor(private authService: AuthService, private route: ActivatedRoute) {}

  ngOnInit() {
    const fromPwa = !!this.route.snapshot.queryParams.fromPwa;
    const isRedirectUrl = window.location.hash.includes('access_token');
    const isErrorUrl = window.location.hash.includes('error');
    if (fromPwa) {
      this.authService.auth0Login();
    } else if (isRedirectUrl || isErrorUrl) {
      this.subscriptions.add(
        this.authService.authState$.subscribe((authState: EBitfAuthState) => {
          this.authState = authState;
          switch (authState) {
            case EBitfAuthState.TOKEN_RETRIEVED:
              window.opener.postMessage(
                {
                  authState,
                  authTokenMetaData: this.authService.authTokenMetaData,
                },
                CONSTANTS.auth0Config.returnTo
              );
              break;
            case EBitfAuthState.EMAIL_NOT_VERIFIED:
              window.opener.postMessage(
                {
                  authState,
                },
                CONSTANTS.auth0Config.returnTo
              );
              break;
          }
        })
      );
      this.authService.handleAuthentication();
    } else {
      this.isVisible = true;
      this.initMessageListener();

      // Try autologin
      this.authService
        .renewToken()
        .then(() => {
          this.authService.handleRedirect();
        })
        .catch(() => {});
    }
  }

  private initMessageListener() {
    window.addEventListener('message', (messageEvent: MessageEvent) => {
      const authState = messageEvent.data && messageEvent.data.authState;
      switch (authState) {
        case EBitfAuthState.TOKEN_RETRIEVED:
          this.authState = authState;
          if (this.popup) {
            this.popup.close();
            this.authService.authTokenMetaData = messageEvent.data.authTokenMetaData;
            this.authService.loadUser();
          }
          break;
        case EBitfAuthState.EMAIL_NOT_VERIFIED:
          this.authState = authState;
          if (this.popup) {
            this.popup.close();
          }
          break;
      }
    });
  }

  // this.authService.loadUser();
  onSignIn() {
    const width = 320;
    const height = 600;
    const left = (screen.width - width) / 2;
    const top = (screen.height - height) / 2;
    let params = 'width=' + width + ', height=' + height;
    params += ', top=' + top + ', left=' + left;
    params += ', directories=no';
    params += ', location=no';
    params += ', menubar=no';
    params += ', resizable=no';
    params += ', scrollbars=no';
    params += ', status=no';
    params += ', toolbar=no';
    this.popup = window.open(`${BITF_CONFIGS.urls.signInUrl}?fromPwa=true`, 'mywindow', params);
    // standard flow
    // this.authService.auth0Login();
  }

  onChangeAccount() {
    this.authService.signOut();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
