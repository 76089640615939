import { UrlSegment, Router, UrlSegmentGroup, UrlTree } from '@angular/router';

export function updateAppLayout(router, isDesktop: boolean, mobilePrefix = 'm') {
  const currentPath: string = router.location.path();

  const isInMobileLayout = currentPath.split('/')[1] === mobilePrefix;
  if (isDesktop && isInMobileLayout) {
    // Redirect to desktop layout
    const path = currentPath.replace(`/${mobilePrefix}`, '');
    router.navigateByUrl(path);
  } else if (!isDesktop && !isInMobileLayout) {
    // Redirect to mobile layout
    router.navigateByUrl(`${mobilePrefix}${currentPath}`);
  }
}

export function initDynamicLayoutUrl(router: Router, storeService, mobilePrefix = 'm') {
  const originalCreateUrlTree = router.createUrlTree.bind(router);
  router.createUrlTree = (...params) => {
    const isDesktop = storeService.store.activeBreakpoints.isDesktop;
    const urlTree: UrlTree = originalCreateUrlTree(...params);
    const firstSegment: string =
      urlTree.root.children.primary && urlTree.root.children.primary.segments[0].path;

    if (isDesktop && firstSegment === mobilePrefix) {
      urlTree.root.children.primary.segments = urlTree.root.segments.slice(1);
    } else if (!isDesktop && firstSegment !== mobilePrefix) {
      if (!firstSegment) {
        const rootChildren = new UrlSegmentGroup([new UrlSegment(mobilePrefix, {})], {});
        rootChildren.parent = urlTree.root;
        urlTree.root.children['primary'] = rootChildren;
      } else {
        urlTree.root.children.primary.segments.unshift(new UrlSegment(mobilePrefix, {}));
      }
    }
    return urlTree;
  };
}
