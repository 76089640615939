import { Component, OnInit, Injector } from '@angular/core';

import { CommonMenuComponent } from '@common/core/components/menu/common-menu.component';

import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { IMenuItem } from '@interfaces';
import { StoreService } from '@services';
import { EBitfCloseEventStatus } from '@enums';

@Component({
  selector: 'cm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends CommonMenuComponent {
  constructor(public injector: Injector, public storeService: StoreService) {
    super(injector);
  }

  menuItems: Array<IMenuItem> = [
    {
      icon: 'person_pin_cirlcle',
      label: bitfToTranslate('COMMON.MENU.CHILDREN_POSITION'),
      url: '/children/list',
    },
    {
      icon: 'directions_bus',
      label: bitfToTranslate('COMMON.MENU.BUS_DRIVER_INFORMATION'),
      url: '/drivers',
    },
    {
      icon: 'people',
      label: bitfToTranslate('COMMON.MENU.YOUR_CHILDREN'),
      url: '/children/edit-list',
    },
    {
      icon: 'account_circle',
      label: bitfToTranslate('COMMON.MENU.MY_PROFILE'),
      url: '/user/profile',
    },
    {
      icon: 'settings',
      label: bitfToTranslate('COMMON.MENU.NOTIFICATION_SETTINGS'),
      url: '/settings-notifications',
    },
    {
      icon: 'email',
      label: bitfToTranslate('COMMON.MENU.CONTACTS'),
      url: '/contacts',
    },
  ];

  signOut() {
    this.mSidenavService.close({ status: EBitfCloseEventStatus.CLOSE });
    this.authService.signOut();
  }

  onRefreshApp() {
    window.location.reload();
  }
}
