<div class="d-flex flex-column justify-content-center align-items-center">
  <img [src]="image"
    class="w-100" />

  <h1 class="my-4">{{ data.title | translate }}</h1>

  <p>{{ data.message | translate }}</p>

  <div *ngIf="data.buttons"
    class="w-100">
    <ng-container *ngFor="let button of data.buttons"
      class="w-100">
      <div [ngSwitch]="button.type">
        <button *ngSwitchCase="mat-flat-button"
          mat-flat-button
          color="{{button.color || ''}}"
          class="w-100"
          (click)="callAction(button.action)">
          {{ button.label | translate }}
        </button>

        <button *ngSwitchDefault
          mat-raised-button
          color="{{button.color || ''}}"
          class="w-100"
          (click)="callAction(button.action)">
          {{ button.label | translate }}
        </button>
      </div>
    </ng-container>
  </div>

</div>