<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <ng-container #sidenavContent></ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <cm-header *ngIf="showHeader"></cm-header>
    <div [class.cm-content-without-header]="!showHeader"
      class="cm-mobile-layout container-fluid">
      <router-outlet></router-outlet>
    </div>
    <cm-footer *ngIf="showFooter && storeService.store?.children?.length"></cm-footer>
  </mat-sidenav-content>
</mat-sidenav-container>