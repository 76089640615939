import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MATERIAL_MODULES, CDK_MODULES } from './material-modules';
import { CommonSharedModule } from '@common/shared/common-shared.module';

// tslint:disable-next-line:max-line-length
import { BitfLoaderComponent } from '@bitf/core/components/ui/loader/bitf-loader.component';
// tslint:disable-next-line:max-line-length
import { BitfAddToHomePromptComponent } from '@bitf/core/components/ui/add-to-home-prompt';
// tslint:disable-next-line:max-line-length
import { BitfMatOkCancelDialogComponent } from '@bitf/core/components/ui/ok-cancel-dialog/material';

// tslint:disable-next-line:max-line-length
import { FullScreenMessageComponent } from './components/full-screen-message/full-screen-message.component';
// tslint:disable-next-line:max-line-length
import { ComponentHeaderComponent } from './components/component-header/component-header.component';
// tslint:disable-next-line:max-line-length
import { DesktopMessageListSwitchComponent } from './components/desktop-message-list-switch/desktop-message-list-switch.component';
import { ChildLastPositionComponent } from '@common/shared/child-last-position/child-last-position.component';

const SHARED_MODULES = [CommonSharedModule, RouterModule, MATERIAL_MODULES, CDK_MODULES];
const SHARED_COMPONENTS = [
  BitfAddToHomePromptComponent,
  BitfMatOkCancelDialogComponent,
  BitfLoaderComponent,
  FullScreenMessageComponent,
  ComponentHeaderComponent,
  DesktopMessageListSwitchComponent,
  ChildLastPositionComponent,
];
const SHARED_DIRECTIVES = [];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
