import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cm-privacy-conditions',
  templateUrl: './privacy-conditions.component.html',
  styleUrls: ['./privacy-conditions.component.scss'],
})
export class PrivacyConditionsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
