import { Component, OnInit } from '@angular/core';

import {
  UsersService,
  ChildrenListsService,
  AppSessionService,
  DialogsService,
  StoreService,
  LoaderService,
} from '@services';
import { EBitfUiMessageType, EBitfUiMessageTarget } from '@enums';

@Component({
  selector: 'cm-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
})
export class DemoComponent implements OnInit {
  constructor(
    private usersService: UsersService,
    private childrenListsService: ChildrenListsService,
    private bitfLoaderService: LoaderService,
    private dialogService: DialogsService,
    private appSessionService: AppSessionService,
    private storeService: StoreService
  ) {}

  ngOnInit() {}

  showError() {
    this.storeService.store.uiMessages$.next({
      type: 'BitfUiMessages',
      strategy: EBitfUiMessageTarget.FULL_SCREEN,
      payload: {
        type: EBitfUiMessageType.ERROR,
        title: 'Error',
        message: 'This is error',
        buttons: [
          {
            action: () => {
              this.dialogService.dialog.closeAll();
            },
            label: 'Great, i am happy!',
            type: 'mat-flat-button',
            class: '',
          },
        ],
      },
    });
  }

  showSuccess() {
    this.storeService.store.uiMessages$.next({
      type: 'BitfUiMessages',
      strategy: EBitfUiMessageTarget.FULL_SCREEN,
      payload: {
        type: EBitfUiMessageType.SUCCESS,
        title: 'Success',
        message: 'This is success',
        buttons: [
          {
            action: 'close',
            label: 'Close this borring message',
            type: 'mat-flat-button',
            class: '',
          },
        ],
      },
    });
  }
}
