import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@common/core/services/guards';

import { DesktopLayoutComponent } from './core/components/desktop-layout/desktop-layout.component';
import { MobileLayoutComponent } from './core/components/mobile-layout/mobile-layout.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';

import { SignInComponent } from './modules/login/sign-in/sign-in.component';
import { SignOutComponent } from '@common/core/components/sign-out/sign-out.component';
import { DemoComponent } from './modules/demo/demo/demo.component';

import { EDesktopSecondaryComponent } from '@enums';

const privateRoutesData = {
  secondaryComponent: EDesktopSecondaryComponent.MAP,
  showHeader: true,
  showFooter: true,
};
const publicRoutesData = {
  secondaryComponent: EDesktopSecondaryComponent.WELCOME_PAGE,
  showHeader: false,
  showFooter: false,
};

const sharedRoutes: Routes = [
  {
    path: 'children',
    loadChildren: () => import('./modules/children/children.module').then(m => m.ChildrenModule),
    data: { ...privateRoutesData },
    canActivate: [AuthGuard],
  },
  {
    path: 'messages',
    loadChildren: () => import('./modules/messages/messages.module').then(m => m.MessagesModule),
    data: { ...privateRoutesData },
    canActivate: [AuthGuard],
  },
  {
    path: 'settings-notifications',
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
    data: { ...privateRoutesData },
    canActivate: [AuthGuard],
  },
  {
    path: 'drivers',
    loadChildren: () => import('./modules/driver/driver.module').then(m => m.DriverModule),
    data: { ...privateRoutesData },
    canActivate: [AuthGuard],
  },
  {
    path: 'demo',
    component: DemoComponent,
    data: { ...privateRoutesData },
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
    data: { ...privateRoutesData },
    canActivate: [AuthGuard],
  },
  {
    path: 'contacts',
    loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule),
    data: { ...privateRoutesData },
    canActivate: [AuthGuard],
  },
  {
    path: 'changelog',
    loadChildren: () =>
      import('./../../common/libs/bitforce/modules/changelog/bitf-changelog.module').then(
        m => m.BitfChangelogModule
      ),
  },
  {
    path: 'sign-in:fromPwa',
    component: SignInComponent,
    data: { ...publicRoutesData },
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    data: { ...publicRoutesData },
  },
  {
    path: 'sign-out',
    component: SignOutComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: { ...publicRoutesData },
  },
];

const routes: Routes = [
  { path: '', redirectTo: 'children/list', pathMatch: 'full' },
  { path: 'm', redirectTo: 'm/children/list', pathMatch: 'full' },
  {
    path: 'm',
    component: MobileLayoutComponent,
    children: [
      {
        path: 'map',
        loadChildren: () => import('./modules/map/map.module').then(m => m.MapModule),
        data: { ...privateRoutesData },
        canActivate: [AuthGuard],
      },
      ...sharedRoutes,
    ],
  },
  {
    path: '',
    component: DesktopLayoutComponent,
    children: [
      {
        path: 'map',
        redirectTo: 'children/list',
        pathMatch: 'full',
      },
      ...sharedRoutes,
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
