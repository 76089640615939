import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PortalModule } from '@angular/cdk/portal';

import { BitfRedirectComponent } from '@bitf/core/components/functional/redirect';

import { SharedModule } from '@shared/shared.module';
import { CommonCoreModule } from '@common/core/common-core.module';
import { MapModule } from '../modules/map/map.module';
import { LoginModule } from './../modules/login/login.module';

import { throwIfAlreadyLoaded } from './module-config';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { MobileLayoutComponent } from './components/mobile-layout/mobile-layout.component';
import { DesktopLayoutComponent } from './components/desktop-layout/desktop-layout.component';
import { FooterComponent } from './components/footer/footer.component';
import { WelcomeDesktopComponent } from './components/welcome-desktop/welcome-desktop.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

const EXPORTS_COMPONENTS = [NotFoundComponent];
const COMPONENTS = [
  MenuComponent,
  BitfRedirectComponent,
  MobileLayoutComponent,
  DesktopLayoutComponent,
  FooterComponent,
  HeaderComponent,
  WelcomeDesktopComponent,
];

@NgModule({
  imports: [CommonCoreModule, RouterModule, SharedModule.forRoot(), LoginModule, PortalModule, MapModule],
  exports: [...EXPORTS_COMPONENTS],
  declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
  providers: [],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
