import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IBitfApiUiMessage } from '@interfaces';
import { EBitfUiMessageType } from '@enums';

@Component({
  selector: 'cm-full-screen-message',
  templateUrl: './full-screen-message.component.html',
  styleUrls: ['./full-screen-message.component.scss'],
})
export class FullScreenMessageComponent implements OnInit {
  pathToImages = 'assets/img/';
  successImage = 'success.png';
  errorImage = 'error.png';

  image: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBitfApiUiMessage,
    public dialogRef: MatDialogRef<FullScreenMessageComponent>
  ) {}

  ngOnInit() {
    this.image =
      this.data.image ||
      `${this.pathToImages}${
        this.data.type === EBitfUiMessageType.ERROR ? this.errorImage : this.successImage
      }`;
  }

  callAction(action: () => void | 'close') {
    if (typeof action === 'function') {
      action();
    } else if (action === 'close') {
      this.dialogRef.close();
    }
  }
}
