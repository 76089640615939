<div class="row justify-content-center px-3 desktop-message-list-switch pb-3"
  *ngIf="isDesktop">
  <a mat-flat-button
    class="col text-center pt-1 text-uppercase"
    routerLink="/children/list"
    routerLinkActive="active-link">
    <mat-icon class="d-block m-auto">people</mat-icon>
    <span class="d-block">{{ 'CHILDREN.STATUS_LIST.HEADER' | translate }}</span>
  </a>
  <a mat-flat-button
    class="col text-center pt-1 text-uppercase"
    routerLink="/messages"
    routerLinkActive="active-link">
    <mat-icon class="d-block m-auto">message</mat-icon>
    <span class="d-block">{{ 'MESSAGES.M_TO_DRIVER.HEADER' | translate }}</span>
  </a>
</div>