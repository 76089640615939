import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import {
  ToastMessagesService,
  StoreService,
  BatchService,
  AppSessionService,
  LoaderService,
} from '@services';
import { IBitfApiResponse, IBitfApiAction, IBitfCloseEvent, IBitfJsonBatchRequest } from '@interfaces';
import { EBitfUiMessageType, EBitfCloseEventStatus, EChildPosition } from '@enums';
import { Child } from '@models';

@Component({
  selector: 'cm-absent-child-dialog',
  templateUrl: './absent-child-dialog.component.html',
  styleUrls: ['./absent-child-dialog.component.scss'],
})
export class AbsentChildDialogComponent implements OnInit {
  childrenList: Child[];
  eChildPosition = EChildPosition;

  isInitialValue = true;

  constructor(
    public storeService: StoreService,
    private dialogRef: MatDialogRef<AbsentChildDialogComponent>,
    private loaderService: LoaderService,
    private toastMessagesService: ToastMessagesService,
    private translateService: TranslateService,
    private batchService: BatchService,
    private appSessionService: AppSessionService
  ) {}

  ngOnInit() {
    this.childrenList = this.storeService.store.children
      // .filter((child: Child) => child.position !== EChildPosition.IN_BUS)
      .map((child: Child) => new Child(child.clone));
  }

  onChangeChildState(child: Child) {
    child.willNotShow = !child.willNotShow;
  }

  onSaveClick() {
    const childrenToUpdate: Child[] = this.childrenList.filter((childFromList: Child) => {
      const origChild = this.storeService.store.children.find(
        (child: Child) => child.id === childFromList.id
      );
      return origChild.willNotShow !== childFromList.willNotShow;
    });

    if (!childrenToUpdate.length) {
      this.dialogRef.close({
        data: null,
        status: EBitfCloseEventStatus.CANCEL,
      } as IBitfCloseEvent<any>);
      return;
    }

    const batchRequest: IBitfJsonBatchRequest[] = childrenToUpdate.map((c: Child) => {
      return {
        method: 'PATCH',
        url: `children(${c.id})`,
        body: { position: EChildPosition.NOT_IN_BUS, willNotShow: c.willNotShow },
      } as IBitfJsonBatchRequest;
    });

    this.loaderService.show();

    this.batchService
      .jsonBatch(batchRequest)
      .pipe(
        finalize(() => {
          this.appSessionService.triggerPolling$.next();
          this.dialogRef.close({
            data: null,
            status: EBitfCloseEventStatus.OK,
          } as IBitfCloseEvent<any>);
        })
      )
      .subscribe(
        (response: IBitfApiResponse<IBitfApiAction>) => {
          const translations = this.getTranslations();

          this.toastMessagesService.show({
            title: translations['COMMON.TOAST_MESSAGES.SUCCESS'],
            message: translations['CHILDREN.ABSENT_DIALOG.TM_SUCCESS'],
            type: EBitfUiMessageType.SUCCESS,
          });
        },
        e => {
          console.log('fix batch error');
        }
      );
  }

  private getTranslations() {
    return this.translateService.instant([
      bitfToTranslate('COMMON.TOAST_MESSAGES.SUCCESS'),
      bitfToTranslate('CHILDREN.ABSENT_DIALOG.TM_SUCCESS'),
      bitfToTranslate('COMMON.TOAST_MESSAGES.ERROR'),
      bitfToTranslate('CHILDREN.ABSENT_DIALOG.TM_ERROR'),
    ]);
  }

  onCancel() {
    this.dialogRef.close({ data: null, status: EBitfCloseEventStatus.CANCEL } as IBitfCloseEvent<any>);
  }
}
