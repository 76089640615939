<div class="d-flex flex-column h-100">
  <h3>{{'CHILDREN.ABSENT_DIALOG.HEADER' | translate}}</h3>

  <mat-selection-list class="row flex-grow-1 cm-scroll-y"
    *ngIf="childrenList">
    <mat-list-option *ngFor="let child of childrenList"
      (click)="onChangeChildState(child)"
      [selected]="child.willNotShow">
      <div class="row no-gutters align-items-center w-100 justify-content-between">
        <div class="col-auto">
          <img src="/assets/img/placeholder-child.png"
            class="rounded-circle" />
        </div>

        <div class="col pl-1">
          {{ child.firstName }} {{ child.lastName }}
        </div>
      </div>
    </mat-list-option>
  </mat-selection-list>

  <div class="row justify-content-end mt-3 flex-shrink-1">
    <div class="col-auto pr-0">
      <button mat-button
        (click)="onCancel()">{{'COMMON.BUTTON.CANCEL' | translate}}</button>
    </div>

    <div class="col-auto">
      <button mat-flat-button
        color="accent"
        (click)="onSaveClick()">{{'COMMON.BUTTON.SAVE' | translate}}</button>
    </div>
  </div>
</div>