import { Component, OnInit, Input } from '@angular/core';

import { AppSessionService, StoreService } from '@services';

@Component({
  selector: 'cm-component-header',
  templateUrl: './component-header.component.html',
  styleUrls: ['./component-header.component.scss'],
})
export class ComponentHeaderComponent implements OnInit {
  @Input()
  header;

  @Input()
  description;

  isDesktop = false;

  constructor(private appSessionService: AppSessionService, private storeService: StoreService) {}

  ngOnInit() {
    this.isDesktop = this.storeService.store.activeBreakpoints.isDesktop;
    this.appSessionService.headerText = this.header;
  }
}
