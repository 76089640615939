import { Component } from '@angular/core';

import { ComponentPortal } from '@angular/cdk/portal';

import { CommonLayout } from '@common/core/components/layout/common-layout.component';

import { EDesktopSecondaryComponent } from '@enums';
import { ParentsMapComponent } from '@web/modules/map/parents-map/parents-map.component';
import { WelcomeDesktopComponent } from '@web/core/components/welcome-desktop/welcome-desktop.component';

@Component({
  selector: 'cm-desktop-layout',
  templateUrl: './desktop-layout.component.html',
  styleUrls: ['./desktop-layout.component.scss'],
})
export class DesktopLayoutComponent extends CommonLayout {
  secondaryComponentPortal: ComponentPortal<any>;
  selectedSecondaryComponent: EDesktopSecondaryComponent;

  updateLayout() {
    const data = this.route.snapshot.firstChild.data;
    if (data.secondaryComponent && data.secondaryComponent !== this.selectedSecondaryComponent) {
      this.selectedSecondaryComponent = data.secondaryComponent;
      switch (this.selectedSecondaryComponent) {
        case EDesktopSecondaryComponent.MAP:
          this.secondaryComponentPortal = new ComponentPortal(ParentsMapComponent);
          break;
        case EDesktopSecondaryComponent.WELCOME_PAGE:
          this.secondaryComponentPortal = new ComponentPortal(WelcomeDesktopComponent);
          break;
        default:
          this.secondaryComponentPortal = null;
      }
    }
    this.showHeader = data.showHeader;
  }
}
